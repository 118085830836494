// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/proxy_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/proxy_type.proto.
 */
export const file_algoenum_v1_proxy_type = /*@__PURE__*/
  fileDesc("ChxhbGdvZW51bS92MS9wcm94eV90eXBlLnByb3RvEgthbGdvZW51bS52MSp1CglQcm94eVR5cGUSGgoWUFJPWFlfVFlQRV9VTlNQRUNJRklFRBAAEhkKFVBST1hZX1RZUEVfREFUQUNFTlRFUhABEhoKFlBST1hZX1RZUEVfUkVTSURFTlRJQUwQAhIVChFQUk9YWV9UWVBFX01PQklMRRADQkdaRWdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYWxnb2VudW0vdjE7YWxnb2VudW12MWIGcHJvdG8z");

/**
 * Describes the enum algoenum.v1.ProxyType.
 */
export const ProxyTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_proxy_type, 0);

/**
 * @generated from enum algoenum.v1.ProxyType
 */
export const ProxyType = /*@__PURE__*/
  tsEnum(ProxyTypeSchema);


// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/transaction_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/transaction_type.proto.
 */
export const file_algoenum_v1_transaction_type = /*@__PURE__*/
  fileDesc("CiJhbGdvZW51bS92MS90cmFuc2FjdGlvbl90eXBlLnByb3RvEgthbGdvZW51bS52MSpsCg9UcmFuc2FjdGlvblR5cGUSIAocVFJBTlNBQ1RJT05fVFlQRV9VTlNQRUNJRklFRBAAEhoKFlRSQU5TQUNUSU9OX1RZUEVfREVCSVQQARIbChdUUkFOU0FDVElPTl9UWVBFX0NSRURJVBACQkdaRWdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYWxnb2VudW0vdjE7YWxnb2VudW12MWIGcHJvdG8z");

/**
 * Describes the enum algoenum.v1.TransactionType.
 */
export const TransactionTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_transaction_type, 0);

/**
 * @generated from enum algoenum.v1.TransactionType
 */
export const TransactionType = /*@__PURE__*/
  tsEnum(TransactionTypeSchema);


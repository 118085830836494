const PERMISSIONS = {
  Login: '/auth.v1.BackofficeAuthService/Login',
  Me: '/auth.v1.BackofficeAuthService/Me',
  FetchApp: '/auth.v1.BackofficeAuthService/FetchApp',
  CreateApp: '/auth.v1.BackofficeAuthService/CreateApp',
  UpdateApp: '/auth.v1.BackofficeAuthService/UpdateApp',
  FetchUser: '/auth.v1.BackofficeAuthService/FetchUser',
  CreateUser: '/auth.v1.BackofficeAuthService/CreateUser',
  UpdateUser: '/auth.v1.BackofficeAuthService/UpdateUser',
  FetchRole: '/auth.v1.BackofficeAuthService/FetchRole',
  CreateRole: '/auth.v1.BackofficeAuthService/CreateRole',
  UpdateRole: '/auth.v1.BackofficeAuthService/UpdateRole',
  FetchService: '/auth.v1.BackofficeAuthService/FetchService',
  CreateService: '/auth.v1.BackofficeAuthService/CreateService',
  UpdateService: '/auth.v1.BackofficeAuthService/UpdateService',
  FetchPath: '/auth.v1.BackofficeAuthService/FetchPath',
  CreatePath: '/auth.v1.BackofficeAuthService/CreatePath',
  UpdatePath: '/auth.v1.BackofficeAuthService/UpdatePath',
  FetchPolicy: '/auth.v1.BackofficeAuthService/FetchPolicy',
  CreatePolicy: '/auth.v1.BackofficeAuthService/CreatePolicy',
  UpdatePolicy: '/auth.v1.BackofficeAuthService/UpdatePolicy',
  ReloadEnforcer: '/auth.v1.BackofficeAuthService/ReloadEnforcer',
  FetchDNS: '/controlplane.dns.v1.DNSBackOfficeService/FetchDNS',
  CreateDNS: '/controlplane.dns.v1.DNSBackOfficeService/CreateDNS',
  UpdateDNS: '/controlplane.dns.v1.DNSBackOfficeService/UpdateDNS',
  FetchTelco: '/controlplane.telco.v1.BackofficeTelcoService/FetchTelco',
  CreateTelco: '/controlplane.telco.v1.BackofficeTelcoService/CreateTelco',
  UpdateTelco: '/controlplane.telco.v1.BackofficeTelcoService/UpdateTelco',
  FetchLocation: '/controlplane.location.v1.BackofficeLocationService/FetchLocation',
  CreateLocation: '/controlplane.location.v1.BackofficeLocationService/CreateLocation',
  UpdateLocation: '/controlplane.location.v1.BackofficeLocationService/UpdateLocation',
  FetchResAccount: '/controlplane.residential.v1.BackofficeResidentialService/FetchResAccount',
  CreateResAccount: '/controlplane.residential.v1.BackofficeResidentialService/CreateResAccount',
  UpdateResAccount: '/controlplane.residential.v1.BackofficeResidentialService/UpdateResAccount',
  FetchResNode: '/controlplane.residential.v1.BackofficeResidentialService/FetchResNode',
  CreateResNode: '/controlplane.residential.v1.BackofficeResidentialService/CreateResNode',
  UpdateResNode: '/controlplane.residential.v1.BackofficeResidentialService/UpdateResNode',
  FetchResPort: '/controlplane.residential.v1.BackofficeResidentialService/FetchResPort',
  UpdateResPort: '/controlplane.residential.v1.BackofficeResidentialService/UpdateResPort',
  RestartResPort: '/controlplane.residential.v1.BackofficeResidentialService/RestartPort',
  FetchResDevice: '/controlplane.residential.v1.BackofficeResidentialService/FetchResDevice',

  FetchMerchantAdmins: '/merchant.v1.MerchantService/ListMerchantUser',
  CreateMerchantAdmin: '/merchant.v1.MerchantService/AddMerchantAdmin',
  FetchBaseProduct: '/merchant.v1.MerchantService/ListProductBase',
  CreateBaseProduct: '/merchant.v1.MerchantService/CreateProductBase',
  UpdateBaseProduct: '/merchant.v1.MerchantService/UpdateProductBase',

  FetchBillingMerchant: '/billing.merchant.v1.MerchantBackofficeService/FetchMerchant',
  CreateBillingMerchant: '/billing.merchant.v1.MerchantBackofficeService/CreateMerchant',
  UpdateBillingMerchant: '/billing.merchant.v1.MerchantBackofficeService/UpdateMerchant',

  FetchBillingPlan: '/billing.plan.v1.BackofficeService/FetchPlan',
  CreateBillingPlan: '/billing.plan.v1.BackofficeService/CreatePlan',
  UpdateBillingPlan: '/billing.plan.v1.BackofficeService/UpdatePlan',
  FetchBillingPlanOption: '/billing.plan.v1.BackofficeService/FetchPlanOption',
  CreateBillingPlanOption: '/billing.plan.v1.BackofficeService/AddPlanOption',
  UpdateBillingPlanOption: '/billing.plan.v1.BackofficeService/UpdatePlanOption',
  FetchBillingPlanExtension: '/billing.plan.v1.BackofficeService/FetchPlanExtension',
  CreateBillingPlanExtension: '/billing.plan.v1.BackofficeService/AddPlanExtension',
  UpdateBillingPlanExtension: '/billing.plan.v1.BackofficeService/UpdatePlanExtension',
  FetchPaymentGateway: '/billing.payment.v1.BackofficeService/FetchPaymentGateway',
  CreatePaymentGateway: '/billing.payment.v1.BackofficeService/CreatePaymentGateway',
  UpdatePaymentGateway: '/billing.payment.v1.BackofficeService/UpdatePaymentGateway',
};

const PERMISSIONS_PAGE = {
  '/accounts': [
    PERMISSIONS.FetchUser,
    PERMISSIONS.FetchRole,
    PERMISSIONS.FetchService,
    PERMISSIONS.FetchPath,
    PERMISSIONS.FetchPolicy,
    PERMISSIONS.FetchApp,
  ],
  '/accounts/users': [PERMISSIONS.FetchUser, PERMISSIONS.CreateUser, PERMISSIONS.UpdateUser],
  '/accounts/roles': [PERMISSIONS.FetchRole, PERMISSIONS.CreateRole, PERMISSIONS.UpdateRole],
  '/accounts/services': [PERMISSIONS.FetchService, PERMISSIONS.CreateService, PERMISSIONS.UpdateService],
  '/accounts/paths': [PERMISSIONS.FetchPath, PERMISSIONS.CreatePath, PERMISSIONS.UpdatePath],
  '/accounts/policies': [PERMISSIONS.FetchPolicy, PERMISSIONS.CreatePolicy, PERMISSIONS.UpdatePolicy],
  '/accounts/apps': [PERMISSIONS.FetchApp, PERMISSIONS.CreateApp, PERMISSIONS.UpdateApp],
  '/dns': [PERMISSIONS.FetchDNS, PERMISSIONS.CreateDNS, PERMISSIONS.UpdateDNS],
  '/telco': [PERMISSIONS.FetchTelco, PERMISSIONS.CreateTelco, PERMISSIONS.UpdateTelco],
  '/location': [PERMISSIONS.FetchLocation, PERMISSIONS.CreateLocation, PERMISSIONS.UpdateLocation],
  '/residential/account': [PERMISSIONS.FetchResAccount, PERMISSIONS.CreateResAccount, PERMISSIONS.UpdateResAccount],
  '/residential/node': [PERMISSIONS.FetchResNode, PERMISSIONS.CreateResNode, PERMISSIONS.UpdateResNode],
  '/residential/port': [PERMISSIONS.FetchResPort, PERMISSIONS.UpdateResPort],
  '/residential/device': [PERMISSIONS.FetchResDevice],
  '/residential': [
    PERMISSIONS.FetchResAccount,
    PERMISSIONS.FetchResNode,
    PERMISSIONS.FetchResPort,
    PERMISSIONS.FetchResDevice,
  ],
  '/merchants': [PERMISSIONS.FetchBaseProduct, PERMISSIONS.FetchMerchants, PERMISSIONS.FetchMerchantAdmins],
  '/merchants/list': [PERMISSIONS.FetchMerchants, PERMISSIONS.CreateMerchant, PERMISSIONS.UpdateMerchants],
  '/merchants/admins': [PERMISSIONS.FetchMerchantAdmins, PERMISSIONS.CreateMerchantAdmin],
  '/merchants/products': [PERMISSIONS.FetchBaseProduct, PERMISSIONS.CreateBaseProduct, PERMISSIONS.UpdateBaseProduct],
  '/billings/plan': [PERMISSIONS.FetchBillingPlan, PERMISSIONS.CreateBillingPlan, PERMISSIONS.UpdateBillingPlan],
  '/billings/plan/option': [
    PERMISSIONS.FetchBillingPlanOption,
    PERMISSIONS.CreateBillingPlanOption,
    PERMISSIONS.UpdateBillingPlanOption,
  ],
  '/billings/plan/extension': [
    PERMISSIONS.FetchBillingPlanExtension,
    PERMISSIONS.CreateBillingPlanExtension,
    PERMISSIONS.UpdateBillingPlanExtension,
  ],
  '/billings/merchant': [
    PERMISSIONS.FetchBillingMerchant,
    PERMISSIONS.CreateBillingMerchant,
    PERMISSIONS.UpdateBillingMerchant,
  ],
};

export { PERMISSIONS, PERMISSIONS_PAGE };

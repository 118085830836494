// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/ip_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/ip_type.proto.
 */
export const file_algoenum_v1_ip_type = /*@__PURE__*/
  fileDesc("ChlhbGdvZW51bS92MS9pcF90eXBlLnByb3RvEgthbGdvZW51bS52MSpHCgZJUFR5cGUSFwoTSVBfVFlQRV9VTlNQRUNJRklFRBAAEhEKDUlQX1RZUEVfSVBfVjQQARIRCg1JUF9UWVBFX0lQX1Y2EAJCR1pFZ2l0LnRtcHJveHktaW5mcmEuY29tL2FsZ28vYWxnb3Byb3h5LXByb3RvL2dlbi9hbGdvZW51bS92MTthbGdvZW51bXYxYgZwcm90bzM");

/**
 * Describes the enum algoenum.v1.IPType.
 */
export const IPTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_ip_type, 0);

/**
 * @generated from enum algoenum.v1.IPType
 */
export const IPType = /*@__PURE__*/
  tsEnum(IPTypeSchema);


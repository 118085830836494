// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/currency.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/currency.proto.
 */
export const file_algoenum_v1_currency = /*@__PURE__*/
  fileDesc("ChphbGdvZW51bS92MS9jdXJyZW5jeS5wcm90bxILYWxnb2VudW0udjEqbAoIQ3VycmVuY3kSGAoUQ1VSUkVOQ1lfVU5TUEVDSUZJRUQQABIQCgxDVVJSRU5DWV9VU0QQARIQCgxDVVJSRU5DWV9WTkQQAhIQCgxDVVJSRU5DWV9USEIQAxIQCgxDVVJSRU5DWV9JRFIQBEJHWkVnaXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL2FsZ29lbnVtL3YxO2FsZ29lbnVtdjFiBnByb3RvMw");

/**
 * Describes the enum algoenum.v1.Currency.
 */
export const CurrencySchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_currency, 0);

/**
 * @generated from enum algoenum.v1.Currency
 */
export const Currency = /*@__PURE__*/
  tsEnum(CurrencySchema);


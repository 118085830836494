// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/data_transfer_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/data_transfer_type.proto.
 */
export const file_algoenum_v1_data_transfer_type = /*@__PURE__*/
  fileDesc("CiRhbGdvZW51bS92MS9kYXRhX3RyYW5zZmVyX3R5cGUucHJvdG8SC2FsZ29lbnVtLnYxKngKEERhdGFUcmFuc2ZlclR5cGUSIgoeREFUQV9UUkFOU0ZFUl9UWVBFX1VOU1BFQ0lGSUVEEAASIAocREFUQV9UUkFOU0ZFUl9UWVBFX1VOTElNSVRFRBABEh4KGkRBVEFfVFJBTlNGRVJfVFlQRV9MSU1JVEVEEAJCR1pFZ2l0LnRtcHJveHktaW5mcmEuY29tL2FsZ28vYWxnb3Byb3h5LXByb3RvL2dlbi9hbGdvZW51bS92MTthbGdvZW51bXYxYgZwcm90bzM");

/**
 * Describes the enum algoenum.v1.DataTransferType.
 */
export const DataTransferTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_data_transfer_type, 0);

/**
 * @generated from enum algoenum.v1.DataTransferType
 */
export const DataTransferType = /*@__PURE__*/
  tsEnum(DataTransferTypeSchema);


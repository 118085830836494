import { store } from '@/reducers';
import { ActionType } from '@/reducers/user';
import { algoService, authService } from '@/services';
import { COOKIE_NAME, TOKEN_NAME } from '@/utils/constants';

const login = async (values) => {
  const { user } = store.getState();
  if (user && user.isLoggedIn) {
    return user;
  }
  try {
    const result = await authService.login(values);
    if (result?.token) {
      signIn({ accessToken: result?.token });
      localStorage.setItem(TOKEN_NAME, result?.token);
      const resultMe = await authService.fetchMe();
      if (resultMe?.paths) {
        const loginUser = {
          email: values?.email,
          accessToken: result?.token,
          listPath: resultMe?.paths,
          isLoggedIn: true,
          userDetail: resultMe?.userDetail,
        };
        signIn(loginUser);
        localStorage.setItem(COOKIE_NAME, JSON.stringify(loginUser));
        return loginUser;
      }
    }
    return result;
  } catch (error) {
    return undefined;
  }
};

const fetchConfig = async () => {
  const { user } = store.getState();
  const configResult = await algoService.fetchConfig();
  if (configResult?.data) {
    signIn({
      config: configResult?.data,
    });
    localStorage.setItem(COOKIE_NAME, JSON.stringify({ ...user, config: configResult.data }));
  }
};

const changePassword = async (values) => {
  const result = await algoService.changePassword(values);
  return result;
};

const signIn = (data) => {
  store.dispatch({
    type: ActionType.USER_LOGIN,
    data,
  });
};

const signOut = () => {
  localStorage.removeItem(COOKIE_NAME);
  localStorage.removeItem(TOKEN_NAME);
  store.dispatch({
    type: ActionType.USER_LOGOUT,
  });
};

export const userAction = {
  login,
  signOut,
  signIn,
  changePassword,
  fetchConfig,
};

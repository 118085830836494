import * as AuthBackOfficeProto from '../algoproxy-proto/genjs/auth/v1/backoffice_auth_pb';
import * as BillingMerchantBackOfficeProto from '../algoproxy-proto/genjs/billing/merchant/v1/backoffice_pb';
import * as BillingPaymentProto from '../algoproxy-proto/genjs/billing/payment/v1/backoffice_pb';
import * as BillingProto from '../algoproxy-proto/genjs/billing/plan/v1/backoffice_pb';
import * as BillingTransactionProto from '../algoproxy-proto/genjs/billing/transaction/v1/backoffice_pb';
import * as DNSBackOfficeProto from '../algoproxy-proto/genjs/controlplane/dns/v1/backoffice_pb';
import * as LocationBackOfficeProto from '../algoproxy-proto/genjs/controlplane/location/v1/backoffice_pb';
import * as ResidentialBackOfficeProto from '../algoproxy-proto/genjs/controlplane/residential/v1/backoffice_pb';
import * as TelcoBackOfficeProto from '../algoproxy-proto/genjs/controlplane/telco/v1/backoffice_pb';
import { client } from './axios';
import serviceGrpc from './grpc';

const login = (body) => client.post(`/control-plane/auth/login`, body);
const fetchMe = () => client.post(`/control-plane/auth/me`, {});
const addUser = (body) => client.post(`/user/add`, body);
const fetchRoles = (body) => client.post(`/control-plane/auth/fetch-role`, body);
const createRole = (body) => client.post(`/control-plane/auth/create-role`, body);
const updateRole = (body) => client.post(`/control-plane/auth/update-role`, body);
const fetchServices = (body) => client.post(`/control-plane/auth/fetch-service`, body);
const createService = (body) => client.post(`/control-plane/auth/create-service`, body);
const updateService = (body) => client.post(`/control-plane/auth/update-service`, body);
const fetchPaths = (body) => client.post(`/control-plane/auth/fetch-path`, body);
const createPath = (body) => client.post(`/control-plane/auth/create-path`, body);
const updatePath = (body) => client.post(`/control-plane/auth/update-path`, body);
const fetchPolicies = (body) => client.post(`/control-plane/auth/fetch-policy`, body);
const createPolicy = (body) => client.post(`/control-plane/auth/create-policy`, body);
const updatePolicy = (body) => client.post(`/control-plane/auth/update-policy`, body);
const fetchUsers = (body) => client.post(`/control-plane/auth/fetch-user`, body);
const createUser = serviceGrpc({
  method: 'createUser',
  schema: AuthBackOfficeProto.BackofficeAuthServiceCreateUserRequestSchema,
  service: AuthBackOfficeProto.BackofficeAuthService,
});
const updateUser = serviceGrpc({
  method: 'updateUser',
  schema: AuthBackOfficeProto.BackofficeAuthServiceUpdateUserRequestSchema,
  service: AuthBackOfficeProto.BackofficeAuthService,
});
const fetchProxies = (params) => client.get(`/proxy-order/list`, { params });
const fetchDashboard = (params) => client.get(`/dashboard`, { params });
const fetchRevenue = (params) => client.get(`/dashboard/deposit`, { params });
const fetchDNS = serviceGrpc({
  method: 'fetchDNS',
  schema: DNSBackOfficeProto.BackofficeDNSServiceFetchDNSRequestSchema,
  service: DNSBackOfficeProto.BackofficeDNSService,
});
const addDNS = serviceGrpc({
  method: 'createDNS',
  schema: DNSBackOfficeProto.BackofficeDNSServiceCreateDNSRequestSchema,
  service: DNSBackOfficeProto.BackofficeDNSService,
});
const updateDNS = serviceGrpc({
  method: 'updateDNS',
  schema: DNSBackOfficeProto.BackofficeDNSServiceUpdateDNSRequestSchema,
  service: DNSBackOfficeProto.BackofficeDNSService,
});
const fetchTelco = serviceGrpc({
  method: 'fetchTelco',
  schema: TelcoBackOfficeProto.BackofficeTelcoServiceFetchTelcoRequestSchema,
  service: TelcoBackOfficeProto.BackofficeTelcoService,
});
const addTelco = serviceGrpc({
  method: 'createTelco',
  schema: TelcoBackOfficeProto.BackofficeTelcoServiceCreateTelcoRequestSchema,
  service: TelcoBackOfficeProto.BackofficeTelcoService,
});
const updateTelco = serviceGrpc({
  method: 'updateTelco',
  schema: TelcoBackOfficeProto.BackofficeTelcoServiceUpdateTelcoRequestSchema,
  service: TelcoBackOfficeProto.BackofficeTelcoService,
});
const fetchLocation = serviceGrpc({
  method: 'fetchLocation',
  schema: LocationBackOfficeProto.BackofficeLocationServiceFetchLocationRequestSchema,
  service: LocationBackOfficeProto.BackofficeLocationService,
});
const addLocation = serviceGrpc({
  method: 'createLocation',
  schema: LocationBackOfficeProto.BackofficeLocationServiceCreateLocationRequestSchema,
  service: LocationBackOfficeProto.BackofficeLocationService,
});
const updateLocation = serviceGrpc({
  method: 'updateLocation',
  schema: LocationBackOfficeProto.BackofficeLocationServiceUpdateLocationRequestSchema,
  service: LocationBackOfficeProto.BackofficeLocationService,
});
const fetchResidentialAccount = serviceGrpc({
  method: 'fetchResAccount',
  schema: ResidentialBackOfficeProto.FetchResAccountRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const addResidentialAccount = serviceGrpc({
  method: 'createResAccount',
  schema: ResidentialBackOfficeProto.CreateResAccountRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const updateResidentialAccount = serviceGrpc({
  method: 'updateResAccount',
  schema: ResidentialBackOfficeProto.UpdateResAccountRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const fetchResidentialNode = serviceGrpc({
  method: 'fetchResNode',
  schema: ResidentialBackOfficeProto.FetchResNodeRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const addResidentialNode = serviceGrpc({
  method: 'createResNode',
  schema: ResidentialBackOfficeProto.CreateResNodeRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const updateResidentialNode = serviceGrpc({
  method: 'updateResNode',
  schema: ResidentialBackOfficeProto.UpdateResNodeRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const fetchResidentialPort = serviceGrpc({
  method: 'fetchResPort',
  schema: ResidentialBackOfficeProto.FetchResPortRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const updateResidentialPort = serviceGrpc({
  method: 'updateResPort',
  schema: ResidentialBackOfficeProto.UpdateResPortRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const restartResidentialPort = serviceGrpc({
  method: 'restartPort',
  schema: ResidentialBackOfficeProto.BackofficeResidentialServiceRestartPortRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const fetchResidentialDevice = serviceGrpc({
  method: 'fetchResDevice',
  schema: ResidentialBackOfficeProto.FetchResDeviceRequestSchema,
  service: ResidentialBackOfficeProto.BackofficeResidentialService,
});
const fetchProducts = (body) => client.post(`/merchant.v1.MerchantService/ListProductBase`, body);
const createProduct = (body) => client.post(`/merchant.v1.MerchantService/CreateProductBase`, body);
const updateProduct = (body) => client.post(`/merchant.v1.MerchantService/UpdateProductBase`, body);
const fetchMerchants = serviceGrpc({
  method: 'fetchMerchant',
  schema: BillingMerchantBackOfficeProto.BackofficeMerchantServiceFetchMerchantRequestSchema,
  service: BillingMerchantBackOfficeProto.BackofficeMerchantService,
});
const addMerchant = serviceGrpc({
  method: 'createMerchant',
  schema: BillingMerchantBackOfficeProto.BackofficeMerchantServiceCreateMerchantRequestSchema,
  service: BillingMerchantBackOfficeProto.BackofficeMerchantService,
});
const updateMerchant = serviceGrpc({
  method: 'updateMerchant',
  schema: BillingMerchantBackOfficeProto.BackofficeMerchantServiceUpdateMerchantRequestSchema,
  service: BillingMerchantBackOfficeProto.BackofficeMerchantService,
});
const fetchMerchantAdmins = (body) => client.post(`/merchant.v1.MerchantService/ListMerchantUser`, body);
const addMerchantAdmin = (body) => client.post(`/merchant.v1.MerchantService/AddMerchantAdmin`, body);
const updateMerchantAdmin = (body) => client.post(`/merchant.v1.MerchantService/UpdateUser`, body);

// Billing
const fetchBillingPlan = serviceGrpc({
  method: 'fetchPlan',
  schema: BillingProto.BackofficePlanServiceFetchPlanRequestSchema,
  service: BillingProto.BackofficePlanService,
});
const createBillingPlan = serviceGrpc({
  method: 'createPlan',
  schema: BillingProto.BackofficePlanServiceCreatePlanRequestSchema,
  service: BillingProto.BackofficePlanService,
});
const updateBillingPlan = serviceGrpc({
  method: 'updatePlan',
  schema: BillingProto.BackofficePlanServiceUpdatePlanRequestSchema,
  service: BillingProto.BackofficePlanService,
});
const fetchPaymentGateway = serviceGrpc({
  method: 'fetchPaymentGateway',
  schema: BillingPaymentProto.BackofficePaymentServiceFetchPaymentGatewayRequestSchema,
  service: BillingPaymentProto.BackofficePaymentService,
});
const createPaymentGateway = serviceGrpc({
  method: 'createPaymentGateway',
  schema: BillingPaymentProto.BackofficePaymentServiceCreatePaymentGatewayRequestSchema,
  service: BillingPaymentProto.BackofficePaymentService,
});
const updatePaymentGateway = serviceGrpc({
  method: 'updatePaymentGateway',
  schema: BillingPaymentProto.BackofficePaymentServiceUpdatePaymentGatewayRequestSchema,
  service: BillingPaymentProto.BackofficePaymentService,
});
const fetchPaymentGatewayType = serviceGrpc({
  method: 'fetchPaymentGatewayType',
  schema: BillingPaymentProto.BackofficePaymentServiceFetchPaymentGatewayTypeRequestSchema,
  service: BillingPaymentProto.BackofficePaymentService,
});
const updatePaymentGatewayType = serviceGrpc({
  method: 'updatePaymentGatewayType',
  schema: BillingPaymentProto.BackofficePaymentServiceUpdatePaymentGatewayTypeRequestSchema,
  service: BillingPaymentProto.BackofficePaymentService,
});
const createBillingPlanPrice = serviceGrpc({
  method: 'createPlanPrice',
  schema: BillingProto.CreatePlanPriceRequestSchema,
  service: BillingProto.BackofficePlanService,
});
const updateBillingPlanPrice = serviceGrpc({
  method: 'updatePlanPrice',
  schema: BillingProto.BackofficePlanServiceUpdatePlanPriceRequestSchema,
  service: BillingProto.BackofficePlanService,
});
const fetchBillingTransaction = serviceGrpc({
  method: 'fetchTransaction',
  schema: BillingTransactionProto.BackofficeTransactionServiceFetchTransactionRequestSchema,
  service: BillingTransactionProto.BackofficeTransactionService,
});

const algoService = {
  updateMerchantAdmin,
  fetchMerchants,
  addMerchant,
  updateMerchant,
  fetchMerchantAdmins,
  addMerchantAdmin,
  restartResidentialPort,
  fetchProducts,
  createProduct,
  updateProduct,
  fetchUsers,
  createUser,
  updateUser,
  fetchPaths,
  createPath,
  updatePath,
  fetchPolicies,
  createPolicy,
  updatePolicy,
  fetchServices,
  createService,
  updateService,
  createRole,
  updateRole,
  fetchMe,
  fetchResidentialDevice,
  fetchResidentialPort,
  updateResidentialPort,
  fetchResidentialNode,
  addResidentialNode,
  updateResidentialNode,
  fetchResidentialAccount,
  addResidentialAccount,
  updateResidentialAccount,
  login,
  addUser,
  fetchRoles,
  fetchProxies,
  fetchDashboard,
  fetchRevenue,
  fetchDNS,
  addDNS,
  updateDNS,
  fetchTelco,
  addTelco,
  updateTelco,
  fetchLocation,
  addLocation,
  updateLocation,
  fetchBillingPlan,
  createBillingPlan,
  updateBillingPlan,
  fetchPaymentGateway,
  createPaymentGateway,
  updatePaymentGateway,
  createBillingPlanPrice,
  updateBillingPlanPrice,
  fetchPaymentGatewayType,
  updatePaymentGatewayType,
  fetchBillingTransaction,
};

export default algoService;

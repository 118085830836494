// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file controlplane/telco/v1/backoffice.proto (package controlplane.telco.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_errmsg_v1_errormsg } from "../../../errmsg/v1/errormsg_pb";
import { file_utils_v1_utils } from "../../../utils/v1/utils_pb";

/**
 * Describes the file controlplane/telco/v1/backoffice.proto.
 */
export const file_controlplane_telco_v1_backoffice = /*@__PURE__*/
  fileDesc("CiZjb250cm9scGxhbmUvdGVsY28vdjEvYmFja29mZmljZS5wcm90bxIVY29udHJvbHBsYW5lLnRlbGNvLnYxIrUBCidCYWNrb2ZmaWNlVGVsY29TZXJ2aWNlRmV0Y2hUZWxjb1JlcXVlc3QSEAoIaWRfdGVsY28YASABKAkSEwoLbmFtZV9zZWFyY2gYAiABKAkSEgoKYXNuX3NlYXJjaBgDIAEoCRIeCgVzdGF0ZRgEIAEoCzIPLnV0aWxzLnYxLlN0YXRlEi8KCnBhZ2luYXRpb24YBSABKAsyGy51dGlscy52MS5QYWdpbmF0aW9uUmVxdWVzdCLAAQooQmFja29mZmljZVRlbGNvU2VydmljZUZldGNoVGVsY29SZXNwb25zZRImCgVlcnJvchgBIAEoCzIXLmVycm1zZy52MS5FcnJvck1lc3NhZ2USMAoKcGFnaW5hdGlvbhgCIAEoCzIcLnV0aWxzLnYxLlBhZ2luYXRpb25SZXNwb25zZRI6CgVpdGVtcxgDIAMoCzIrLmNvbnRyb2xwbGFuZS50ZWxjby52MS5CYWNrb2ZmaWNlVGVsY29Nb2RlbCJFCihCYWNrb2ZmaWNlVGVsY29TZXJ2aWNlQ3JlYXRlVGVsY29SZXF1ZXN0EgwKBG5hbWUYASABKAkSCwoDYXNuGAIgASgJIlMKKUJhY2tvZmZpY2VUZWxjb1NlcnZpY2VDcmVhdGVUZWxjb1Jlc3BvbnNlEiYKBWVycm9yGAEgASgLMhcuZXJybXNnLnYxLkVycm9yTWVzc2FnZSJ3CihCYWNrb2ZmaWNlVGVsY29TZXJ2aWNlVXBkYXRlVGVsY29SZXF1ZXN0EhAKCGlkX3RlbGNvGAEgASgJEgwKBG5hbWUYAiABKAkSCwoDYXNuGAMgASgJEh4KBXN0YXRlGAQgASgLMg8udXRpbHMudjEuU3RhdGUiUwopQmFja29mZmljZVRlbGNvU2VydmljZVVwZGF0ZVRlbGNvUmVzcG9uc2USJgoFZXJyb3IYASABKAsyFy5lcnJtc2cudjEuRXJyb3JNZXNzYWdlIlYKFEJhY2tvZmZpY2VUZWxjb01vZGVsEhAKCGlkX3RlbGNvGAEgASgJEgwKBG5hbWUYAiABKAkSCwoDYXNuGAMgASgJEhEKCWlzX2FjdGl2ZRgEIAEoCDLOAwoWQmFja29mZmljZVRlbGNvU2VydmljZRKNAQoKRmV0Y2hUZWxjbxI+LmNvbnRyb2xwbGFuZS50ZWxjby52MS5CYWNrb2ZmaWNlVGVsY29TZXJ2aWNlRmV0Y2hUZWxjb1JlcXVlc3QaPy5jb250cm9scGxhbmUudGVsY28udjEuQmFja29mZmljZVRlbGNvU2VydmljZUZldGNoVGVsY29SZXNwb25zZRKQAQoLQ3JlYXRlVGVsY28SPy5jb250cm9scGxhbmUudGVsY28udjEuQmFja29mZmljZVRlbGNvU2VydmljZUNyZWF0ZVRlbGNvUmVxdWVzdBpALmNvbnRyb2xwbGFuZS50ZWxjby52MS5CYWNrb2ZmaWNlVGVsY29TZXJ2aWNlQ3JlYXRlVGVsY29SZXNwb25zZRKQAQoLVXBkYXRlVGVsY28SPy5jb250cm9scGxhbmUudGVsY28udjEuQmFja29mZmljZVRlbGNvU2VydmljZVVwZGF0ZVRlbGNvUmVxdWVzdBpALmNvbnRyb2xwbGFuZS50ZWxjby52MS5CYWNrb2ZmaWNlVGVsY29TZXJ2aWNlVXBkYXRlVGVsY29SZXNwb25zZUJOWkxnaXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL2NvbnRyb2xwbGFuZS90ZWxjby92MTt0ZWxjb3YxYgZwcm90bzM", [file_errmsg_v1_errormsg, file_utils_v1_utils]);

/**
 * Describes the message controlplane.telco.v1.BackofficeTelcoServiceFetchTelcoRequest.
 * Use `create(BackofficeTelcoServiceFetchTelcoRequestSchema)` to create a new message.
 */
export const BackofficeTelcoServiceFetchTelcoRequestSchema = /*@__PURE__*/
  messageDesc(file_controlplane_telco_v1_backoffice, 0);

/**
 * Describes the message controlplane.telco.v1.BackofficeTelcoServiceFetchTelcoResponse.
 * Use `create(BackofficeTelcoServiceFetchTelcoResponseSchema)` to create a new message.
 */
export const BackofficeTelcoServiceFetchTelcoResponseSchema = /*@__PURE__*/
  messageDesc(file_controlplane_telco_v1_backoffice, 1);

/**
 * Describes the message controlplane.telco.v1.BackofficeTelcoServiceCreateTelcoRequest.
 * Use `create(BackofficeTelcoServiceCreateTelcoRequestSchema)` to create a new message.
 */
export const BackofficeTelcoServiceCreateTelcoRequestSchema = /*@__PURE__*/
  messageDesc(file_controlplane_telco_v1_backoffice, 2);

/**
 * Describes the message controlplane.telco.v1.BackofficeTelcoServiceCreateTelcoResponse.
 * Use `create(BackofficeTelcoServiceCreateTelcoResponseSchema)` to create a new message.
 */
export const BackofficeTelcoServiceCreateTelcoResponseSchema = /*@__PURE__*/
  messageDesc(file_controlplane_telco_v1_backoffice, 3);

/**
 * Describes the message controlplane.telco.v1.BackofficeTelcoServiceUpdateTelcoRequest.
 * Use `create(BackofficeTelcoServiceUpdateTelcoRequestSchema)` to create a new message.
 */
export const BackofficeTelcoServiceUpdateTelcoRequestSchema = /*@__PURE__*/
  messageDesc(file_controlplane_telco_v1_backoffice, 4);

/**
 * Describes the message controlplane.telco.v1.BackofficeTelcoServiceUpdateTelcoResponse.
 * Use `create(BackofficeTelcoServiceUpdateTelcoResponseSchema)` to create a new message.
 */
export const BackofficeTelcoServiceUpdateTelcoResponseSchema = /*@__PURE__*/
  messageDesc(file_controlplane_telco_v1_backoffice, 5);

/**
 * Describes the message controlplane.telco.v1.BackofficeTelcoModel.
 * Use `create(BackofficeTelcoModelSchema)` to create a new message.
 */
export const BackofficeTelcoModelSchema = /*@__PURE__*/
  messageDesc(file_controlplane_telco_v1_backoffice, 6);

/**
 * @generated from service controlplane.telco.v1.BackofficeTelcoService
 */
export const BackofficeTelcoService = /*@__PURE__*/
  serviceDesc(file_controlplane_telco_v1_backoffice, 0);


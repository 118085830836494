// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/location_level.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/location_level.proto.
 */
export const file_algoenum_v1_location_level = /*@__PURE__*/
  fileDesc("CiBhbGdvZW51bS92MS9sb2NhdGlvbl9sZXZlbC5wcm90bxILYWxnb2VudW0udjEqZQoNTG9jYXRpb25MZXZlbBIeChpMT0NBVElPTl9MRVZFTF9VTlNQRUNJRklFRBAAEhoKFkxPQ0FUSU9OX0xFVkVMX0NPVU5UUlkQARIYChRMT0NBVElPTl9MRVZFTF9TVEFURRACQkdaRWdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYWxnb2VudW0vdjE7YWxnb2VudW12MWIGcHJvdG8z");

/**
 * Describes the enum algoenum.v1.LocationLevel.
 */
export const LocationLevelSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_location_level, 0);

/**
 * @generated from enum algoenum.v1.LocationLevel
 */
export const LocationLevel = /*@__PURE__*/
  tsEnum(LocationLevelSchema);


// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/payment_gateway_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/payment_gateway_type.proto.
 */
export const file_algoenum_v1_payment_gateway_type = /*@__PURE__*/
  fileDesc("CiZhbGdvZW51bS92MS9wYXltZW50X2dhdGV3YXlfdHlwZS5wcm90bxILYWxnb2VudW0udjEq6gEKElBheW1lbnRHYXRld2F5VHlwZRIkCiBQQVlNRU5UX0dBVEVXQVlfVFlQRV9VTlNQRUNJRklFRBAAEiAKHFBBWU1FTlRfR0FURVdBWV9UWVBFX1ZDQl9WTkQQARIgChxQQVlNRU5UX0dBVEVXQVlfVFlQRV9BQ0JfVk5EEAISIgoeUEFZTUVOVF9HQVRFV0FZX1RZUEVfVk5QQVlfVk5EEAMSIgoeUEFZTUVOVF9HQVRFV0FZX1RZUEVfQURNSU5fVk5EEAQSIgoeUEFZTUVOVF9HQVRFV0FZX1RZUEVfQURNSU5fVVNEEAVCR1pFZ2l0LnRtcHJveHktaW5mcmEuY29tL2FsZ28vYWxnb3Byb3h5LXByb3RvL2dlbi9hbGdvZW51bS92MTthbGdvZW51bXYxYgZwcm90bzM");

/**
 * Describes the enum algoenum.v1.PaymentGatewayType.
 */
export const PaymentGatewayTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_payment_gateway_type, 0);

/**
 * @generated from enum algoenum.v1.PaymentGatewayType
 */
export const PaymentGatewayType = /*@__PURE__*/
  tsEnum(PaymentGatewayTypeSchema);


// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/res_node.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/res_node.proto.
 */
export const file_algoenum_v1_res_node = /*@__PURE__*/
  fileDesc("ChphbGdvZW51bS92MS9yZXNfbm9kZS5wcm90bxILYWxnb2VudW0udjEqaQoNUmVzTm9kZVN0YXR1cxIfChtSRVNfTk9ERV9TVEFUVVNfVU5TUEVDSUZJRUQQABIbChdSRVNfTk9ERV9TVEFUVVNfT0ZGTElORRABEhoKFlJFU19OT0RFX1NUQVRVU19PTkxJTkUQAiqhAQoRUmVzTm9kZVBvd2VyU3RhdGUSJAogUkVTX05PREVfUE9XRVJfU1RBVEVfVU5TUEVDSUZJRUQQABIcChhSRVNfTk9ERV9QT1dFUl9TVEFURV9PRkYQARIbChdSRVNfTk9ERV9QT1dFUl9TVEFURV9PThACEisKJ1JFU19OT0RFX1BPV0VSX1NUQVRFX1BPV0VSX0lEX0lOQ09SUkVDVBADKpABCg9SZXNEZXZpY2VTdGF0dXMSIQodUkVTX0RFVklDRV9TVEFUVVNfVU5TUEVDSUZJRUQQABIdChlSRVNfREVWSUNFX1NUQVRVU19PRkZMSU5FEAESHAoYUkVTX0RFVklDRV9TVEFUVVNfT05MSU5FEAISHQoZUkVTX0RFVklDRV9TVEFUVVNfT05fRElBTBADQkdaRWdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYWxnb2VudW0vdjE7YWxnb2VudW12MWIGcHJvdG8z");

/**
 * Describes the enum algoenum.v1.ResNodeStatus.
 */
export const ResNodeStatusSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_res_node, 0);

/**
 * @generated from enum algoenum.v1.ResNodeStatus
 */
export const ResNodeStatus = /*@__PURE__*/
  tsEnum(ResNodeStatusSchema);

/**
 * Describes the enum algoenum.v1.ResNodePowerState.
 */
export const ResNodePowerStateSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_res_node, 1);

/**
 * @generated from enum algoenum.v1.ResNodePowerState
 */
export const ResNodePowerState = /*@__PURE__*/
  tsEnum(ResNodePowerStateSchema);

/**
 * Describes the enum algoenum.v1.ResDeviceStatus.
 */
export const ResDeviceStatusSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_res_node, 2);

/**
 * @generated from enum algoenum.v1.ResDeviceStatus
 */
export const ResDeviceStatus = /*@__PURE__*/
  tsEnum(ResDeviceStatusSchema);


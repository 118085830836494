// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/transaction_status.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/transaction_status.proto.
 */
export const file_algoenum_v1_transaction_status = /*@__PURE__*/
  fileDesc("CiRhbGdvZW51bS92MS90cmFuc2FjdGlvbl9zdGF0dXMucHJvdG8SC2FsZ29lbnVtLnYxKpkBChFUcmFuc2FjdGlvblN0YXR1cxIiCh5UUkFOU0FDVElPTl9TVEFUVVNfVU5TUEVDSUZJRUQQABIhCh1UUkFOU0FDVElPTl9TVEFUVVNfUFJPQ0VTU0lORxABEh4KGlRSQU5TQUNUSU9OX1NUQVRVU19TVUNDRVNTEAISHQoZVFJBTlNBQ1RJT05fU1RBVFVTX0ZBSUxFRBADQkdaRWdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYWxnb2VudW0vdjE7YWxnb2VudW12MWIGcHJvdG8z");

/**
 * Describes the enum algoenum.v1.TransactionStatus.
 */
export const TransactionStatusSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_transaction_status, 0);

/**
 * @generated from enum algoenum.v1.TransactionStatus
 */
export const TransactionStatus = /*@__PURE__*/
  tsEnum(TransactionStatusSchema);


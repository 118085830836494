// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file controlplane/dns/v1/backoffice.proto (package controlplane.dns.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_errmsg_v1_errormsg } from "../../../errmsg/v1/errormsg_pb";
import { file_utils_v1_utils } from "../../../utils/v1/utils_pb";
import { file_algoenum_v1_ip_type } from "../../../algoenum/v1/ip_type_pb";
import { file_controlplane_dns_v1_entity } from "./entity_pb";

/**
 * Describes the file controlplane/dns/v1/backoffice.proto.
 */
export const file_controlplane_dns_v1_backoffice = /*@__PURE__*/
  fileDesc("CiRjb250cm9scGxhbmUvZG5zL3YxL2JhY2tvZmZpY2UucHJvdG8SE2NvbnRyb2xwbGFuZS5kbnMudjEi1AEKI0JhY2tvZmZpY2VETlNTZXJ2aWNlRmV0Y2hETlNSZXF1ZXN0Eg4KBmlkX2RucxgBIAEoCRITCgtuYW1lX3NlYXJjaBgCIAEoCRIRCglpcF9zZWFyY2gYAyABKAkSJAoHaXBfdHlwZRgEIAEoDjITLmFsZ29lbnVtLnYxLklQVHlwZRIeCgVzdGF0ZRgFIAEoCzIPLnV0aWxzLnYxLlN0YXRlEi8KCnBhZ2luYXRpb24YBiABKAsyGy51dGlscy52MS5QYWdpbmF0aW9uUmVxdWVzdCKpAQokQmFja29mZmljZUROU1NlcnZpY2VGZXRjaEROU1Jlc3BvbnNlEiYKBWVycm9yGAEgASgLMhcuZXJybXNnLnYxLkVycm9yTWVzc2FnZRIwCgpwYWdpbmF0aW9uGAIgASgLMhwudXRpbHMudjEuUGFnaW5hdGlvblJlc3BvbnNlEicKBWl0ZW1zGAMgAygLMhguY29udHJvbHBsYW5lLmRucy52MS5ETlMiUAokQmFja29mZmljZUROU1NlcnZpY2VDcmVhdGVETlNSZXF1ZXN0EgwKBG5hbWUYASABKAkSDAoEZG5zMRgCIAEoCRIMCgRkbnMyGAMgASgJIk8KJUJhY2tvZmZpY2VETlNTZXJ2aWNlQ3JlYXRlRE5TUmVzcG9uc2USJgoFZXJyb3IYASABKAsyFy5lcnJtc2cudjEuRXJyb3JNZXNzYWdlIoABCiRCYWNrb2ZmaWNlRE5TU2VydmljZVVwZGF0ZUROU1JlcXVlc3QSDgoGaWRfZG5zGAEgASgJEgwKBG5hbWUYAiABKAkSDAoEZG5zMRgDIAEoCRIMCgRkbnMyGAQgASgJEh4KBXN0YXRlGAUgASgLMg8udXRpbHMudjEuU3RhdGUiTwolQmFja29mZmljZUROU1NlcnZpY2VVcGRhdGVETlNSZXNwb25zZRImCgVlcnJvchgBIAEoCzIXLmVycm1zZy52MS5FcnJvck1lc3NhZ2UyoQMKFEJhY2tvZmZpY2VETlNTZXJ2aWNlEn8KCEZldGNoRE5TEjguY29udHJvbHBsYW5lLmRucy52MS5CYWNrb2ZmaWNlRE5TU2VydmljZUZldGNoRE5TUmVxdWVzdBo5LmNvbnRyb2xwbGFuZS5kbnMudjEuQmFja29mZmljZUROU1NlcnZpY2VGZXRjaEROU1Jlc3BvbnNlEoIBCglDcmVhdGVETlMSOS5jb250cm9scGxhbmUuZG5zLnYxLkJhY2tvZmZpY2VETlNTZXJ2aWNlQ3JlYXRlRE5TUmVxdWVzdBo6LmNvbnRyb2xwbGFuZS5kbnMudjEuQmFja29mZmljZUROU1NlcnZpY2VDcmVhdGVETlNSZXNwb25zZRKCAQoJVXBkYXRlRE5TEjkuY29udHJvbHBsYW5lLmRucy52MS5CYWNrb2ZmaWNlRE5TU2VydmljZVVwZGF0ZUROU1JlcXVlc3QaOi5jb250cm9scGxhbmUuZG5zLnYxLkJhY2tvZmZpY2VETlNTZXJ2aWNlVXBkYXRlRE5TUmVzcG9uc2VCSlpIZ2l0LnRtcHJveHktaW5mcmEuY29tL2FsZ28vYWxnb3Byb3h5LXByb3RvL2dlbi9jb250cm9scGxhbmUvZG5zL3YxO2Ruc3YxYgZwcm90bzM", [file_errmsg_v1_errormsg, file_utils_v1_utils, file_algoenum_v1_ip_type, file_controlplane_dns_v1_entity]);

/**
 * Describes the message controlplane.dns.v1.BackofficeDNSServiceFetchDNSRequest.
 * Use `create(BackofficeDNSServiceFetchDNSRequestSchema)` to create a new message.
 */
export const BackofficeDNSServiceFetchDNSRequestSchema = /*@__PURE__*/
  messageDesc(file_controlplane_dns_v1_backoffice, 0);

/**
 * Describes the message controlplane.dns.v1.BackofficeDNSServiceFetchDNSResponse.
 * Use `create(BackofficeDNSServiceFetchDNSResponseSchema)` to create a new message.
 */
export const BackofficeDNSServiceFetchDNSResponseSchema = /*@__PURE__*/
  messageDesc(file_controlplane_dns_v1_backoffice, 1);

/**
 * Describes the message controlplane.dns.v1.BackofficeDNSServiceCreateDNSRequest.
 * Use `create(BackofficeDNSServiceCreateDNSRequestSchema)` to create a new message.
 */
export const BackofficeDNSServiceCreateDNSRequestSchema = /*@__PURE__*/
  messageDesc(file_controlplane_dns_v1_backoffice, 2);

/**
 * Describes the message controlplane.dns.v1.BackofficeDNSServiceCreateDNSResponse.
 * Use `create(BackofficeDNSServiceCreateDNSResponseSchema)` to create a new message.
 */
export const BackofficeDNSServiceCreateDNSResponseSchema = /*@__PURE__*/
  messageDesc(file_controlplane_dns_v1_backoffice, 3);

/**
 * Describes the message controlplane.dns.v1.BackofficeDNSServiceUpdateDNSRequest.
 * Use `create(BackofficeDNSServiceUpdateDNSRequestSchema)` to create a new message.
 */
export const BackofficeDNSServiceUpdateDNSRequestSchema = /*@__PURE__*/
  messageDesc(file_controlplane_dns_v1_backoffice, 4);

/**
 * Describes the message controlplane.dns.v1.BackofficeDNSServiceUpdateDNSResponse.
 * Use `create(BackofficeDNSServiceUpdateDNSResponseSchema)` to create a new message.
 */
export const BackofficeDNSServiceUpdateDNSResponseSchema = /*@__PURE__*/
  messageDesc(file_controlplane_dns_v1_backoffice, 5);

/**
 * @generated from service controlplane.dns.v1.BackofficeDNSService
 */
export const BackofficeDNSService = /*@__PURE__*/
  serviceDesc(file_controlplane_dns_v1_backoffice, 0);


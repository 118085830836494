// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file controlplane/dns/v1/entity.proto (package controlplane.dns.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_algoenum_v1_ip_type } from "../../../algoenum/v1/ip_type_pb";

/**
 * Describes the file controlplane/dns/v1/entity.proto.
 */
export const file_controlplane_dns_v1_entity = /*@__PURE__*/
  fileDesc("CiBjb250cm9scGxhbmUvZG5zL3YxL2VudGl0eS5wcm90bxITY29udHJvbHBsYW5lLmRucy52MSJ4CgNETlMSDgoGaWRfZG5zGAEgASgJEiQKB2lwX3R5cGUYAiABKA4yEy5hbGdvZW51bS52MS5JUFR5cGUSDAoEbmFtZRgDIAEoCRIMCgRkbnMxGAQgASgJEgwKBGRuczIYBSABKAkSEQoJaXNfYWN0aXZlGAYgASgIQkpaSGdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vY29udHJvbHBsYW5lL2Rucy92MTtkbnN2MWIGcHJvdG8z", [file_algoenum_v1_ip_type]);

/**
 * Describes the message controlplane.dns.v1.DNS.
 * Use `create(DNSSchema)` to create a new message.
 */
export const DNSSchema = /*@__PURE__*/
  messageDesc(file_controlplane_dns_v1_entity, 0);


// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file utils/v1/utils.proto (package utils.v1, syntax proto3)
/* eslint-disable */

import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file utils/v1/utils.proto.
 */
export const file_utils_v1_utils = /*@__PURE__*/
  fileDesc("ChR1dGlscy92MS91dGlscy5wcm90bxIIdXRpbHMudjEiMQoFU3RhdGUSEQoJaXNfYWN0aXZlGAEgASgIEhUKDWlzX2RlYWN0aXZhdGUYAiABKAgiOwoRUGFnaW5hdGlvblJlcXVlc3QSEQoJcGFnZV9zaXplGAEgASgEEhMKC3BhZ2VfbnVtYmVyGAIgASgEImEKElBhZ2luYXRpb25SZXNwb25zZRIUCgxjdXJyZW50X3BhZ2UYASABKAMSEQoJcGFnZV9zaXplGAIgASgDEg0KBXRvdGFsGAMgASgDEhMKC3RvdGFsX3BhZ2VzGAQgASgDQkFaP2dpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vdXRpbHMvdjE7dXRpbHN2MWIGcHJvdG8z");

/**
 * Describes the message utils.v1.State.
 * Use `create(StateSchema)` to create a new message.
 */
export const StateSchema = /*@__PURE__*/
  messageDesc(file_utils_v1_utils, 0);

/**
 * Describes the message utils.v1.PaginationRequest.
 * Use `create(PaginationRequestSchema)` to create a new message.
 */
export const PaginationRequestSchema = /*@__PURE__*/
  messageDesc(file_utils_v1_utils, 1);

/**
 * Describes the message utils.v1.PaginationResponse.
 * Use `create(PaginationResponseSchema)` to create a new message.
 */
export const PaginationResponseSchema = /*@__PURE__*/
  messageDesc(file_utils_v1_utils, 2);


export const isMobile = () => {
  const mobiles = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  return mobiles.some((matches) => navigator.userAgent.match(matches));
};

export const COOKIE_NAME = 'algo';
export const TOKEN_NAME = 'token';

// eslint-disable-next-line no-undef
export const API_URL = process.env.REACT_APP_API;
// eslint-disable-next-line no-undef
export const API_AUTH_URL = process.env.REACT_APP_AUTH_API;
export const API_MYIP_URL = 'https://myip.algoproxy.com/';

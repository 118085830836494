import { Redirect, Route, Switch } from 'react-router-dom';

import { useCallback, useEffect, useState } from 'react';

import './App.scss';
import PrivateRoute from './PrivateRoute';
import { userAction } from './actions/user';
import LoadingIndicator from './components/LoadingIndicator';
import ScrollToTop from './components/ScrollToTop';
import BillingMerchant from './pages/Billing/MerchantConfig/Loadable';
import Payments from './pages/Billing/Payments/Loadable';
import BillingPlan from './pages/Billing/Plan/Loadable';
import BillingTransaction from './pages/Billing/Transaction/Loadable';
// import Dashboard from './pages/Dashboard/Loadable';
import DNS from './pages/DNS/Loadable';
import Location from './pages/Location/Loadable';
import Login from './pages/Login/Loadable';
import Apps from './pages/ManageAccount/Apps/Loadable';
import Paths from './pages/ManageAccount/Paths/Loadable';
import Policies from './pages/ManageAccount/Policies/Loadable';
import Roles from './pages/ManageAccount/Roles/Loadable';
import Services from './pages/ManageAccount/Services/Loadable';
import Users from './pages/ManageAccount/Users/Loadable';
import MerchantsAdmins from './pages/Merchants/Admins/Loadable';
import MerchantsList from './pages/Merchants/Merchants/Loadable';
import MerchantsProducts from './pages/Merchants/Products/Loadable';
import ResidentialAccount from './pages/Residential/Account/Loadable';
import ResidentialDevice from './pages/Residential/Device/Loadable';
import ResidentialNode from './pages/Residential/Node/Loadable';
import ResidentialPort from './pages/Residential/Port/Loadable';
import Telco from './pages/Telco/Loadable';
import { COOKIE_NAME } from './utils/constants';

function App() {
  const [isReadyUser, setIsReadyUser] = useState(false);

  const loadUser = useCallback(async () => {
    try {
      const profileJson = localStorage.getItem(COOKIE_NAME);
      if (profileJson) {
        const profile = JSON.parse(profileJson);
        await userAction.signIn(profile);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsReadyUser(true);
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (!isReadyUser)
    return (
      <div className='App'>
        <LoadingIndicator />
      </div>
    );

  return (
    <div className='App'>
      <ScrollToTop />
      <Switch>
        <Route path='/login' component={Login} />
        <PrivateRoute path='/accounts/users' component={Users} />
        <PrivateRoute path='/accounts/roles' component={Roles} />
        <PrivateRoute path='/accounts/services' component={Services} />
        <PrivateRoute path='/accounts/paths' component={Paths} />
        <PrivateRoute path='/accounts/policies' component={Policies} />
        <PrivateRoute path='/accounts/apps' component={Apps} />
        <PrivateRoute path='/controlPlan/dns' component={DNS} />
        <PrivateRoute path='/controlPlan/telco' component={Telco} />
        <PrivateRoute path='/controlPlan/location' component={Location} />
        <PrivateRoute path='/merchants/list' component={MerchantsList} />
        <PrivateRoute path='/merchants/admins' component={MerchantsAdmins} />
        <PrivateRoute path='/merchants/products' component={MerchantsProducts} />
        <PrivateRoute path='/residential/account' component={ResidentialAccount} />
        <PrivateRoute path='/residential/node' component={ResidentialNode} />
        <PrivateRoute path='/residential/port' component={ResidentialPort} />
        <PrivateRoute path='/residential/device' component={ResidentialDevice} />
        <PrivateRoute path='/billings/merchant' exact component={BillingMerchant} />
        <PrivateRoute path='/billings/merchant/plan' exact component={BillingPlan} />
        <PrivateRoute path='/billings/payments' component={Payments} />
        <PrivateRoute path='/billings/transactions' component={BillingTransaction} />
        {/* <Route path='/' component={Dashboard} /> */}
        <Route path=''>
          <Redirect to='/accounts/users' />
        </Route>
      </Switch>
    </div>
  );
}

export default App;

import * as AuthProto from '../algoproxy-proto/genjs/auth/v1/backoffice_auth_pb';
import serviceGrpc from './grpc';

const authServiceGrpc = (method, schema) =>
  serviceGrpc({
    method,
    schema,
    service: AuthProto.BackofficeAuthService,
  });

const fetchApps = authServiceGrpc('fetchApp', AuthProto.BackofficeAuthServiceFetchAppRequestSchema);
const createApp = authServiceGrpc('createApp', AuthProto.BackofficeAuthServiceCreateAppRequestSchema);
const updateApp = authServiceGrpc('updateApp', AuthProto.BackofficeAuthServiceUpdateAppRequestSchema);
const fetchRoles = authServiceGrpc('fetchRole', AuthProto.BackofficeAuthServiceFetchRoleRequestSchema);
const createRole = authServiceGrpc('createRole', AuthProto.BackofficeAuthServiceCreateRoleRequestSchema);
const updateRole = authServiceGrpc('updateRole', AuthProto.BackofficeAuthServiceUpdateRoleRequestSchema);
const fetchUsers = authServiceGrpc('fetchUser', AuthProto.BackofficeAuthServiceFetchUserRequestSchema);
const createUser = authServiceGrpc('createUser', AuthProto.BackofficeAuthServiceCreateUserRequestSchema);
const updateUser = authServiceGrpc('updateUser', AuthProto.BackofficeAuthServiceUpdateUserRequestSchema);
const fetchServices = authServiceGrpc('fetchService', AuthProto.BackofficeAuthServiceFetchServiceRequestSchema);
const createService = authServiceGrpc('createService', AuthProto.BackofficeAuthServiceCreateServiceRequestSchema);
const updateService = authServiceGrpc('updateService', AuthProto.BackofficeAuthServiceUpdateServiceRequestSchema);
const fetchPaths = authServiceGrpc('fetchPath', AuthProto.BackofficeAuthServiceFetchPathRequestSchema);
const createPath = authServiceGrpc('createPath', AuthProto.BackofficeAuthServiceCreatePathRequestSchema);
const updatePath = authServiceGrpc('updatePath', AuthProto.BackofficeAuthServiceUpdatePathRequestSchema);
const fetchPolicies = authServiceGrpc('fetchPolicy', AuthProto.BackofficeAuthServiceFetchPolicyRequestSchema);
const createPolicy = authServiceGrpc('createPolicy', AuthProto.BackofficeAuthServiceCreatePolicyRequestSchema);
const updatePolicy = authServiceGrpc('updatePolicy', AuthProto.BackofficeAuthServiceUpdatePolicyRequestSchema);
const login = authServiceGrpc('login', AuthProto.BackofficeAuthServiceLoginRequestSchema);
const reloadEnforcer = authServiceGrpc('reloadEnforcer', AuthProto.BackofficeAuthServiceReloadEnforcerRequestSchema);
const fetchMe = authServiceGrpc('me', AuthProto.BackofficeAuthServiceMeRequestSchema);

const authService = {
  fetchMe,
  reloadEnforcer,
  login,
  fetchPolicies,
  createPolicy,
  updatePolicy,
  fetchPaths,
  createPath,
  updatePath,
  fetchServices,
  createService,
  updateService,
  fetchUsers,
  createUser,
  updateUser,
  fetchRoles,
  createRole,
  updateRole,
  fetchApps,
  createApp,
  updateApp,
};

export default authService;

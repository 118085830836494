// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/order_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/order_type.proto.
 */
export const file_algoenum_v1_order_type = /*@__PURE__*/
  fileDesc("ChxhbGdvZW51bS92MS9vcmRlcl90eXBlLnByb3RvEgthbGdvZW51bS52MSpVCglPcmRlclR5cGUSGgoWT1JERVJfVFlQRV9VTlNQRUNJRklFRBAAEhUKEU9SREVSX1RZUEVfQ1JFQVRFEAESFQoRT1JERVJfVFlQRV9FWFRFTkQQAkJHWkVnaXQudG1wcm94eS1pbmZyYS5jb20vYWxnby9hbGdvcHJveHktcHJvdG8vZ2VuL2FsZ29lbnVtL3YxO2FsZ29lbnVtdjFiBnByb3RvMw");

/**
 * Describes the enum algoenum.v1.OrderType.
 */
export const OrderTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_order_type, 0);

/**
 * @generated from enum algoenum.v1.OrderType
 */
export const OrderType = /*@__PURE__*/
  tsEnum(OrderTypeSchema);


import { create } from '@bufbuild/protobuf';
import { createClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';

import { userAction } from '@/actions/user';
import { ErrorCode } from '@/algoproxy-proto/genjs/errmsg/v1/errormsg_pb';
import { bigInt } from '@/utils/helper/number';

const logger = (next) => async (req) => {
  req.header.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
  const res = await next(req);
  if (res?.message?.error?.code === ErrorCode['UNAUTHORIZED']) {
    userAction.signOut();
    return;
  }
  return res;
};

const transport = createConnectTransport({
  // eslint-disable-next-line no-undef
  baseUrl: process.env.REACT_APP_API,
  interceptors: [logger],
});

const clientGrpc = (service) => createClient(service, transport);

const serviceGrpc = ({ method, schema, service }) => {
  return (body) => {
    const pagination = body?.pagination;
    if (pagination) {
      body.pagination = {
        pageNumber: bigInt(pagination.pageNumber || 1),
        pageSize: bigInt(pagination.pageSize || 100),
      };
    }
    const requestGrpc = create(schema, body);
    return clientGrpc(service)[method](requestGrpc);
  };
};

export default serviceGrpc;

// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/app_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/app_type.proto.
 */
export const file_algoenum_v1_app_type = /*@__PURE__*/
  fileDesc("ChphbGdvZW51bS92MS9hcHBfdHlwZS5wcm90bxILYWxnb2VudW0udjEqTQoHQXBwVHlwZRIYChRBUFBfVFlQRV9VTlNQRUNJRklFRBAAEhEKDUFQUF9UWVBFX0NPUkUQARIVChFBUFBfVFlQRV9NRVJDSEFOVBACQkdaRWdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYWxnb2VudW0vdjE7YWxnb2VudW12MWIGcHJvdG8z");

/**
 * Describes the enum algoenum.v1.AppType.
 */
export const AppTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_app_type, 0);

/**
 * @generated from enum algoenum.v1.AppType
 */
export const AppType = /*@__PURE__*/
  tsEnum(AppTypeSchema);


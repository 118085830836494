import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';

import React from 'react';

import App from './App';
import { store } from './reducers';
import reportWebVitals from './reportWebVitals';
import { queryClient } from './services';
import history from './utils/history';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <ConfigProvider theme={{ hashed: false }}>
          <App />
        </ConfigProvider>
      </Router>
    </QueryClientProvider>
  </ReduxProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/change_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/change_type.proto.
 */
export const file_algoenum_v1_change_type = /*@__PURE__*/
  fileDesc("Ch1hbGdvZW51bS92MS9jaGFuZ2VfdHlwZS5wcm90bxILYWxnb2VudW0udjEqWwoKQ2hhbmdlVHlwZRIbChdDSEFOR0VfVFlQRV9VTlNQRUNJRklFRBAAEhYKEkNIQU5HRV9UWVBFX1NUQVRJQxABEhgKFENIQU5HRV9UWVBFX1JPVEFUSU5HEAJCR1pFZ2l0LnRtcHJveHktaW5mcmEuY29tL2FsZ28vYWxnb3Byb3h5LXByb3RvL2dlbi9hbGdvZW51bS92MTthbGdvZW51bXYxYgZwcm90bzM");

/**
 * Describes the enum algoenum.v1.ChangeType.
 */
export const ChangeTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_change_type, 0);

/**
 * @generated from enum algoenum.v1.ChangeType
 */
export const ChangeType = /*@__PURE__*/
  tsEnum(ChangeTypeSchema);


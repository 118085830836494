// @generated by protoc-gen-es v2.2.0 with parameter "target=js"
// @generated from file algoenum/v1/balance_type.proto (package algoenum.v1, syntax proto3)
/* eslint-disable */

import { enumDesc, fileDesc, tsEnum } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file algoenum/v1/balance_type.proto.
 */
export const file_algoenum_v1_balance_type = /*@__PURE__*/
  fileDesc("Ch5hbGdvZW51bS92MS9iYWxhbmNlX3R5cGUucHJvdG8SC2FsZ29lbnVtLnYxKl4KC0JhbGFuY2VUeXBlEhwKGEJBTEFOQ0VfVFlQRV9VTlNQRUNJRklFRBAAEhUKEUJBTEFOQ0VfVFlQRV9NQUlOEAESGgoWQkFMQU5DRV9UWVBFX1BST01PVElPThACQkdaRWdpdC50bXByb3h5LWluZnJhLmNvbS9hbGdvL2FsZ29wcm94eS1wcm90by9nZW4vYWxnb2VudW0vdjE7YWxnb2VudW12MWIGcHJvdG8z");

/**
 * Describes the enum algoenum.v1.BalanceType.
 */
export const BalanceTypeSchema = /*@__PURE__*/
  enumDesc(file_algoenum_v1_balance_type, 0);

/**
 * @generated from enum algoenum.v1.BalanceType
 */
export const BalanceType = /*@__PURE__*/
  tsEnum(BalanceTypeSchema);

